import React from 'react';

const Footer = () => {
    const currentYear = new Date().getFullYear();

    return (
        <footer className="bg-gray-900 text-white py-4">
            <div className="container mx-auto flex justify-between items-center">
                <div className="flex space-x-4">
                    <a href="https://forum.ascalter.de/imprint/" className="hover:underline">Impressum</a>
                    <a href="https://forum.ascalter.de/terms/" className="hover:underline">Datenschutz</a>
                </div>
                <div className="text-center">
                    <p className="text-lg">Ascalter.de © {currentYear}</p>
                </div>
                <div className="text-right">
                    <p>Made with ❤️ by xXSwainXx</p>
                </div>
            </div>
        </footer>
    );
};

export default Footer;