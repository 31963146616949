import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

const ChatLog = () => {
    const { id } = useParams();
    const [chatLog, setChatLog] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);

    useEffect(() => {
        const fetchMessages = async () => {
            setChatLog(null);
            setError(false);
            setLoading(true);

            if (id) {
                try {
                    const response = await fetch(`http://localhost:3005/chat-logs/${id}`);
                    if (!response.ok) throw new Error('Nachrichten konnten nicht abgerufen werden.');

                    const data = await response.json();
                    if (!data) {
                        setError(true);
                    } else {
                        setChatLog(data);
                    }
                } catch (e) {
                    console.error('Fehler beim Abrufen der Chat-Logs:', e);
                    setError(true);
                } finally {
                    setLoading(false);
                }
            } else {
                setLoading(false);
            }
        };

        fetchMessages();
    }, [id]);

    if (error) {
        return (
            <div className="flex flex-col items-center justify-center h-full text-center">
                <div className="bg-gray-700 p-8 rounded-lg shadow-lg">
                    <h1 className="text-3xl mb-4">ID nicht gefunden!</h1>
                    <p className="text-lg mb-4">Die eingegebene ID konnte nicht gefunden werden.</p>
                    <p className="text-sm">Bitte versuche es erneut oder gehe zurück zur <a href="/" className="text-blue-400 underline">Startseite</a>.</p>
                </div>
            </div>
        );
    }

    if (loading) return <div className="text-center text-white">Nachrichten werden abgerufen...</div>;

    if (!chatLog) return null;

    let jsonChatLogEntities;

    try {
        jsonChatLogEntities = JSON.parse(chatLog.jsonChatLogEntities);
    } catch (e) {
        console.error('Fehler beim Parsen von jsonChatLogEntities:', e);
        return <div className="text-center text-white">Fehler beim Verarbeiten der Chat-Log-Daten</div>;
    }

    return (
        <div className="p-4 space-y-2">
            <h1 className="text-3xl mb-4">Chatlog für ID: {id}</h1>
            {jsonChatLogEntities.map((entity, index) => (
                <div
                    key={index}
                    className={`bg-gray-800 px-3 py-2 rounded flex items-center space-x-2 font-minecraft text-base text-white ${entity.sender === chatLog.targetUniqueId ? 'border border-red-400 bg-red-800 bg-opacity-25' : ''}`}
                >
                    {/* Datum und Zeitangabe */}
                    <span className="text-gray-400">
                        [{new Date(entity.timeStamp).toLocaleString('de-DE', {
                        day: '2-digit',
                        month: '2-digit',
                        year: 'numeric',
                        hour: '2-digit',
                        minute: '2-digit'
                    })}]
                    </span>

                    {/* Minecraft-Skin-Avatar */}
                    <img
                        src={`https://crafatar.com/avatars/${entity.sender}?size=30&overlay`}
                        alt={`Avatar`}
                        className="rounded-sm"
                    />

                    {/* Spielername und Nachricht */}
                    <div>
                        <span className="text-gray-400 font-bold">{entity.senderName}</span>:
                        <span className="ml-1 text-white">{entity.message}</span>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default ChatLog;